.block-style {
    display: inline-block;
}
.ctv-container .payment-details-ctv {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.ctv-container .SettingScreen a:focus{border-radius:7px; transform:scale(1.04); cursor:pointer; background-color:#acacaa;}

@media (max-width:1400px) {
    .ctv-container li.settingScale:focus {border-radius:4px;}
}

/* .ctv-container #standard-grid.grid-view .swiper-container .thumbLink:focus {
	transform: scale(1.07);
}

.ctv-container #standard-grid.grid-view .swiper-container .thumbLink:focus + .titleHolder p {
	transform: scale(1.07);
	text-shadow: 0 3px 20px rgba(0,0,0, .4);
}

.ctv-container .grid-view .thumbLink:focus {transform:scale(1.07); -webkit-transform:scale(1.07); -moz-transform:scale(1.07); -ms-transform:scale(1.07); -o-transform:scale(1.07); -webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden; backface-visibility: hidden; -webkit-transform-style: preserve-3d;-webkit-font-smoothing: antialiased; transform: perspective(1px) scale(1.1); 
}

.ctv-container .logInScreen input:focus, .ctv-container .searchContent .thumbLink:focus {-webkit-transform:scale(1.07); -ms-transform:scale(1.07); transform:scale(1.07);} */

.ctv-container .jumbo-view .swiper-slide:focus, .ctv-container .jumbo-view .slideHover{transform:scale(1.06); -webkit-transform:scale(1.06); -moz-transform:scale(1.06); -ms-transform:scale(1.06); -o-transform:scale(1.06); -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden; -ms-backface-visibility: hidden;backface-visibility: hidden; -webkit-transform-style: preserve-3d;-webkit-font-smoothing: antialiased; transform: perspective(1px) scale(1.06);}



/* .ctv-container .swiper-slide:focus {
	outline: 2px solid red;
}

.ctv-container :focus {
	outline: 2px solid red;
} */

.ctv-container .focusable:focus {
	/* outline: 2px solid red !important; */
	box-shadow: 0px 2px 5px 7px #afafaf;
}

/* .ctv-container .swiper-slide:focus {
	outline: 2px solid red;
} */

/* .ctv-container a:focus {
	outline: 2px solid red;
} */

.ctv-container .with-modal #nav-bar {
	position: relative;
	/* position: fixed;
	top: 0; */
	width: 100%;
}

.ctv-container .wh-100 {
	width: 100%;
	height: 100%;
}

.ctv-container .hg-black {
	background: #525252 !important;
}

.ctv-container .hg-black:active {
	background: #fff !important;
}

.ctv-container .video-inlineMenu .menu{
	padding: 30px;
	margin-top: 30px;
	background: #f3f1f1;
	/* width: 100%; */
	/* width: max-content; */
	/* min-width: 100%; */
	min-width: max-content;
	height: 315px;
	transition: height 450ms;
    box-sizing: border-box;
}
.ctv-container #fullscreen * * .detail-swiper > .thumbLink:focus + div.titleHolder{
    display:block;
}


.ctv-container #standard-grid.grid-view .swiper-container .thumbLink:focus {
	transform: scale(1.07);
}
.ctv-container #standard-grid.grid-view .swiper-container .thumbLink:focus + .titleHolder p {
	transform: scale(1.07);
	text-shadow: 0 3px 20px rgba(0,0,0, .4);
}

.ctv-container .grid-view .thumbLink:focus, .grid-view .thumbLink.slideHover{transform:scale(1.07); -webkit-transform:scale(1.07); -moz-transform:scale(1.07); -ms-transform:scale(1.07); -o-transform:scale(1.07); -webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden; backface-visibility: hidden; -webkit-transform-style: preserve-3d;-webkit-font-smoothing: antialiased; transform: perspective(1px) scale(1.1); }

.ctv-container .logInScreen input:hover, 
.ctv-container .logInScreen input.btnHover, 
.ctv-container .searchContent .thumbLink:focus, 
.ctv-container .searchContent .thumbLink.slideHover{-webkit-transform:scale(1.07); -ms-transform:scale(1.07); transform:scale(1.07);}

.ctv-container a.thumbLink.thumbHover:focus .img-video .img-thumb, 
.ctv-container a.thumbLink.hoverElmnt .img-video .img-thumb{opacity:1;}
.ctv-container a.thumbLink.thumbHover:focus .img-video .video-thumb, 
.ctv-container a.thumbLink.hoverElmnt .img-video .video-thumb, 
.ctv-container .img-thumb, 
.ctv-container .SettingScreen li, 
.ctv-container .detail .summaryHolder, 
.ctv-container .search-row, 
.ctv-container .showFeVideo, 
.ctv-container .showVideo, 
.ctv-container .showImage, 
.ctv-container .showGallery, 
.ctv-container .searchContent .thumbLink, 
.ctv-container .grid-view .thumbLink, 
.ctv-container img.bye, 
.ctv-container .titleHolder span, 
.ctv-container .saveIcon span{display:block;}

.ctv-container a.thumbLink.thumbHover, .ctv-container a.thumbLink { display: block;}
.ctv-container .video-inlineMenu .menu.not-visible {
    padding: 0;
    margin: 0;
    height: 0;
}

.ctv-container .successImgIcon {
	background: url("../images/success.png");
	background-size: contain;
	width: 200px;
	height: 200px;
}

.ctv-container .failureImgIcon {
	background: url("../images/failure.png");
	background-size: contain;
	width: 200px;
	height: 200px;
}

.ctv-container .fix-nav > .nav-view + div {
	margin-top: 0;
}

.ctv-container div:not(.fix-nav) > #nav-bar + div .user-access {
	margin-top: 0;
}
.ctv-container #standard-grid.grid-view .titleHolder a {
	align-self: flex-start;
}
.ctv-container .search-section .search-row {
	/* display: grid; */
	display: flex;
	flex-wrap: wrap;
}
.ctv-container .search-section .search-row .searchContent {
    flex-basis: 28% !important;
    width: initial !important;
    margin: 15px !important;	
}
.ctv-container .data-privacy .privacy-item {
	display: flex;
    align-items: center;
    justify-content: start;
}
.ctv-container .data-privacy .privacy-item p {
	width: calc(100% - 450px);
}
.ctv-container .data-privacy .privacy-item a {
	width: 225px;
}
.ctv-container #myframe {
	height: 100vh;
}
.ctv-container #nav-bar + div .subscribeScreen-wrapper {
	padding: 0;
}

.ctv-container #nav-bar + div .subscribeScreen-wrapper .subscription-cntnt {
	min-height: calc(100vh - 270px);
}
.ctv-container .video-inlineMenu .menu .swiper-container .swiper-slide {
	padding-bottom: 0;
}
.ctv-container .detail-view-cls .swiper-container-vertical .detailContent {
	position: fixed;
}
.ctv-container .detail-view-cls .detail-view .swiper-container.swiper-container-vertical {
	padding-top: 30px;
}
.ctv-container .detail-view-cls > div > h2 {
	position: fixed;
}
.ctv-container .detail-view-cls .detail-view .swiper-slide.swiper-slide-active {
	transform: scale(1);
	transform: perspective(1px) scale(1)
}

.ctv-container .detail-view-cls .detail-view .swiper-slide.focusedEle {
	transform: scale(1.06);
	transform: perspective(1px) scale(1.06)
}

.ctv-container #detail-view-content .swiper-wrapper {
	transform: translate3d(0,0,0) !important;
}
.ctv-container .loader-scrn{
	position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* background: rgba(0,0,0,0.97); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
	align-items: center;
	background: #efefef;
	z-index: 10;
}
.ctv-container .loader-scrn img.logoHolder, 
.ctv-container .payment-details-ctv img.logoHolder {
	max-height: 60px;
}
.ctv-container .loader-scrn > .logoHolder {
	margin-top: -250px !important;
}
.ctv-container .loader-scrn .loader-details {
	font-size: 25px;
	color: black;
	background: white;
    padding: 40px 80px;
}

.ctv-container .loader-scrn .loader-details b {
	font-size: 30px;
    margin-left: 30px;
}

.ctv-container .payment-details-ctv .loader-details {
	min-width: 900px;
	font-size: 22px;
	margin-top: 40px;
}

.ctv-container .payment-details-ctv .loader-details b {
	margin-left: 0;
	display: block;
}

.ctv-container .payment-details-ctv .loader-details > div {
	margin: 5px 0;
}

.ctv-container .fb-loader-details {
	background: white;
    color: black;
	width: 1000px;
	position: relative;
}

.ctv-container .fb-loader-details span {
	position: absolute;
    top: 5px;
    left: 5px;
}

.ctv-container .fb-loader-details span img {
	width: 40px;
}

.ctv-container .fb-loader-details .fb-code {
    text-align: center;
    padding: 50px 0;
    background: lightgray;
    color: #3b5998;
    font-size: 72px;
}

.ctv-container .fb-loader-details .fb-desc {
    padding: 65px 90px;
    box-sizing: border-box;
    text-align: center;
}

.ctv-container .fb-desc b {
	font-size: 32px;
}

.ctv-container .bckgrnds .videoBg > div {
	width: 100%;
    height: 100%;
}

.ctv-container .bckgrnds .videoBg > div video {
	min-height: 100vh;
}

.ctv-container #fullscreen-grid .activeInside + span h2,
.ctv-container #fullscreen-grid .activeInside + span .ctaHolder {
	display: none;
}

.ctv-container .SettingScreen a {
	width: 100%;
}

.ctv-container iframe {
	outline: none !important;
	border: none !important;
}

.ctv-container .d-block {
	display: block;
}