  /*SpotLight Theme CSS*/

  .h-screen {
    height: 100vh;
  }

  .bottom-0 {
    bottom: 0;
  }

  .fixed {
    position: fixed;
  }

  .z-10 {
    z-index: 10;
  }

  .z-0 {
    z-index: 0;
  }

  .h-full {
    width: 100%;
  }

  .left-0 {
    left: 0;
  }

  .top-0 {
      top: 0;
  }

  .right-0 {
      right: 0;
  }

  .absolute {
    position: absolute;
  }

  #previewSection{
    position: fixed;
    height: 70%;
    width: 100%;
    margin: auto;
    top: 0px;
    z-index: 0;
  }

  #previewDescription{
    width: 40%;
    left: 61px;
    height: 200px;
    position:relative;
    top: 90px;
    z-index:10;
  }

  #previewVideo{
    position: absolute;
    right:0px;
    top:0px;
    width: 58%;
    height: 100%;
    z-index: 5;
  }

  #previewImage{
    position: absolute;
    right:0px;
    top:0px;
    width: 58%;
    height: 100%;
    z-index: 5;
  }

  .previewTitle{
    width: 100%;
    font-size: 30px;
  }

  .previewSubtitle{
    white-space: nowrap;
    width: 100%;
    text-align: left;
    font-size: 19px;
    margin-top: 10px;
    height: 22px;
    color: grey;
    text-overflow: ellipsis;
  }

  #previewSummary{
    width: 100%;
    font-size: 19px;
    margin-top: 10px;
    overflow: hidden;
  }


  #spotlight-preview_html5_api, #spotlight-preview{
    position: absolute;
    top:0px;
    object-fit: cover;
    height: 100%;
    right: 0px;
  }

  #image-preview{
    height: 100%;
    width:100%;
    object-fit: cover;
    right: 0px;
  }

  #previewShadow{
    position: absolute;
    top:0px;
    right: 0px;
    width: 100%;
    height: 100%;
    box-shadow: inset 150px -225px 225px 0 rgba(0, 0, 0, 1);
  }


  #spotlight-grid{
    width: 100%;
    height: 55%;
    bottom: 0;
    z-index: 15;
    position: fixed;
  }

  .spotlight * * div.img-video{
    border-radius: 10px;
    border-color: black;
  }

  #previewVideo > .vjs-ended > .vjs-poster {
    display: block;
  }

  .spotlight.grid-view .swiper-container{height:250px;}
