body {
	--video_inline_item_width: 18vw;
	--video_inline_item_height: calc(var(--video_inline_item_width) * 0.5625);
	--video_inline_title_height: 80px;
}
/* Common Css*/
.white-bg {
	background-color: white;
}

.font-black {
	color: black;
}

.scroll-auto-h-100 {
	overflow: auto;
	height: 100%;
}

.overflow-hidden {
	overflow: hidden;
}

video:focus {
	outline: none;
}
.with-modal .no-logo-div {
	height: 50px;
}
.text-center {
	text-align: center;
}
.thumbLink img.img-thumb {
	object-fit: cover;
	object-position: center;
}
/* CSS for navbar auto content width */
.slider-box.nav-view > .swiper-container.swiper-container-horizontal {
    width: "intrinsic";           /* Safari/WebKit uses a non-standard name */
    width: "-moz-max-content";    /* Firefox/Gecko */
    width: "-webkit-max-content"; /* Chrome */
    width: max-content;
}
.videoFull .vjs-control-bar {
	display: none;
}
/* CSS for Video player -> Inline Menu */
.video-inlineMenu {
	position: absolute;
	bottom: 0px;
	z-index: 99;
	width: 100%;
	overflow: hidden;
}
.video-inlineMenu.closedMenu {
	bottom: 70px;
}
.video-inlineMenu.closingNow {
	bottom: -40px;
}
@-moz-document url-prefix() {
	/* firefox */
	.video-inlineMenu.closedMenu {
		bottom: 40px;
	}
}
@media not all and (min-resolution:.001dpcm) {
	/* safari */
	@supports (-webkit-appearance:none) {
		.video-inlineMenu.closedMenu {
			bottom: 40px;
		}
	}
}
.videoFull .video-play-extra-button {
	position: absolute;
	top: calc(50% - 55px);
  left: calc(50% - 36px);
}
.videoFull .video-play-extra-button img {
	cursor: pointer;
	background-color: #fff;
	border-radius: 50%;
	padding: 4px;
}
.videoFull .bumpable-ima-ad-container div:first-child {
	/* lonlinear google ad */
	top: 200px;
	height: 100px !important;
}
.videoFull .bumpable-ima-ad-container div:first-child iframe{
	/* lonlinear google ad */
	height: 100px;
}
.videoFull .bumpable-ima-ad-container .ima-controls-div {
	display: none
}
.video-inlineMenu .button-toggle {
	text-align: center;
}
.video-inlineMenu .button-toggle img{
	height: 50px;
    cursor: pointer;
    width: 50px;
    background: #0000007a;
    padding: 12px;
    border-radius: 50%;
}
.video-inlineMenu .menu{
	padding: 30px;
	margin-top: 30px;
	background: #f3f1f1;
	width: 100%;
	/* Height = item height + title height + padding(60) */
	height: calc(var(--video_inline_item_height) + var(--video_inline_title_height) + 60px); 
	transition: height 450ms;
    box-sizing: border-box;
}
.video-inlineMenu .menu.inlineMenuAnimation {
	overflow: hidden;
}
.video-inlineMenu .menu .swiper-container {
	overflow: inherit;
}
.video-inlineMenu .menu .swiper-container .swiper-slide {
	overflow: inherit;
}
.video-inlineMenu .menu .swiper-slide {
	background: none;
}
.video-inlineMenu .menu.not-visible {
	padding: 0;
	margin: 0;
	height: 0;
}
.video-inlineMenu.closingNow .menu {
	height: 0;
}
.video-inlineMenuItem {
	width: var(--video_inline_item_width) !important;
}
.video-inlineMenu .video-inlineMenuItem img {
	height: var(--video_inline_item_height);
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
	display: block;
	text-align: center;
	object-fit: cover;
}
.video-inlineMenu .video-inlineMenuItem span {
	border-radius: 5px;
	width: 100%;
	display: inline-block;
}
.video-inlineMenu .video-inlineMenuItem span:hover,
.video-inlineMenu .video-inlineMenuItem span.selected {
	transform: scale(1.08);
	box-shadow: 0 6px 10px 10px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.video-inlineMenu .video-inlineMenuItem span.limited {
	opacity: 0.5;
	cursor: not-allowed;
}
.video-inlineMenu .video-inlineMenuItem .title{
	height: var(--video_inline_title_height);
	text-align: center;
	color: black;
	margin: 0 5px;
	overflow: hidden;
}
.video-inlineMenu .video-inlineMenuItem .title p {
	overflow: hidden;
	margin: 15px 0 0 0;
	font-size: 24px;
    line-height: 32px;
}

/* Style to fix nav bar */
.fix-nav > div:first-child {
	position: fixed;
	top: 0;
	width: 100%;
}
.fix-nav > .nav-view + div {
	margin-top: 140px;
}

/* in case of overlay remove fixed from nav-bar */
#overlay + .fix-nav > .nav-view {
    position: relative;
}
#nav-bar .disable-header-link,
#web-nav-bar .disable-header-link {
	pointer-events: none;
}

.vjs-error .vjs-error-display:before {
	content: "";
	margin: 0;
}

/* Css for subscription modal */
.subscribeScreen-wrapper {
	min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Video player  */
.prevThumbnail img {
    height: 150px;
    width: 270px;
}
.prevThumbnail {
    width: 270px;
}
.prevThumbnail p {
    font-size: 16px;
}
.backBtn {
    width: 60px;
    height: 60px;
}
.metering-counter, .video-title {
    background-color: #040404;
    width: 240px;
    height: 65px;
    border-radius: 10px;
    text-align: center;
    line-height: 60px;
}
.video-title p {
    margin: 0px 10px !important;
    font-size: 20px;
}
.metering-counter span {
    font-size: 24px;
}
.image-view .metering-counter span {
	position: static;
}

.ellipsis p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* Spotlight theme*/
.spotlight * * div.img-video img{
	border-radius: 10px;
}
#spotlight-grid .swiper-slide.detail-swiper,
#standard-grid.grid-view .swiper-slide.detail-swiper{
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
}
#spotlight-grid .spotlight > .ctaHolder p {
	margin: 0 auto;
}

#spotlight-grid .spotlight.grid-view > * {
	padding: 0 60px;
	margin: 0;
}

#spotlight-grid .spotlight.grid-view > *:first-child {
	padding-top: 25px;
	font-size: 34px;
}

#spotlight-grid .spotlight.grid-view > .swiper-container {
	height: 100%;
	padding-top: 25px;
	overflow: visible;
}

#spotlight-grid > .swiper-container.swiper-container-vertical > div > span,
#fullscreen-grid > .swiper-container.swiper-container-vertical > div > span {
	min-height: "intrinsic";           /* Safari/WebKit uses a non-standard name */
    min-height: "-moz-max-content";    /* Firefox/Gecko */
    min-height: "-webkit-max-content"; /* Chrome */
	min-height: max-content;
	max-height: "intrinsic";           /* Safari/WebKit uses a non-standard name */
    max-height: "-moz-max-content";    /* Firefox/Gecko */
    max-height: "-webkit-max-content"; /* Chrome */
    max-height: max-content;
	height: fit-content !important;
}

/* Big-title */
#spotlight-section > #previewSection > #previewDescription .previewTitle{
	font-size: 40px;
	font-weight: bold;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	overflow: hidden;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
}

/*	Subtitle */
#spotlight-section > #previewSection > #previewDescription .previewSubtitle{
	font-size: 27px;
	overflow: hidden;
	height: auto;
}
/*Description*/
#spotlight-section > #previewSection > #previewDescription #previewSummary{
	font-size: 27px;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	overflow: hidden;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
	margin-top: 0;
}

#spotlight-grid .spotlight.grid-view > .swiper-container a.thumbLink img.img-thumb {
	max-width: 100%;
}

/* Fullscreen Css (fixes)*/
.swiper-container-vertical.vert-container>.swiper-wrapper {
	background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .5) 10%, rgba(0, 0, 0, .7) 100%);
}

#fullscreen-grid {
	height: 100vh;
}

#fullscreen-grid .spotlight > .ctaHolder p {
	margin: 0 auto;
}

#fullscreen-grid .spotlight.grid-view > * {
	padding: 0 60px;
	margin: 0;
}

#fullscreen-grid .spotlight.grid-view > *:first-child {
	padding-top: 32px;
	font-size: 35px;
}

#fullscreen-grid .spotlight.grid-view > .swiper-container {
	height: 100%;
	padding-top: 18px;
	overflow: visible;
}

#fullscreen-grid .spotlight.grid-view > .swiper-container div.titleHolder p {
	font-size: 29px;
	height: auto;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	overflow: hidden;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
}

/*Standard Grid View */
#standard-grid.grid-view .swiper-container {
	padding: 22px 100px 0;
	margin-bottom: 20px;
}
#standard-grid.grid-view .ctaHolder {
	font-size: 42px;
	padding: 50px 0 10px 100px;
	margin: 0;
}
#standard-grid.grid-view .ctaHolder p {
	margin: 0px;
}
#standard-grid.grid-view h2 {
	padding: 50px 100px 10px;
	font-size: 42px;
	margin: 0;
}
#standard-grid.grid-view .swiper-container,
#standard-grid.nothumbBox.grid-view .swiper-container {
	/* max-height: 445px; */
	height: auto;
}
#standard-grid.grid-view.portrait-view .swiper-container,
#standard-grid.nothumbBox.grid-view.portrait-view .swiper-container {
	/* max-height: 640px; */
	height: auto;
}
#standard-grid.grid-view .titleHolder {
	display: flex;
    justify-content: space-evenly;
	align-items: center;
	max-height: 90px;
    margin-top: 40px;
}
#standard-grid.grid-view.portrait-view .titleHolder {
	max-height: 140px;
}
#standard-grid.grid-view .titleHolder p,
#standard-grid.nothumbBox.grid-view .titleHolder p {
	font-size: 36px;
	display: -webkit-box;
	height: auto;
	max-height: 100px;
	margin: 0px 0 20px 0;
	flex: 1;
	display: flex;
	justify-content: center;
}
#standard-grid.grid-view.portrait-view .titleHolder p {
	max-height: 140px;
	margin: 0px 0 10px 0;
}
#standard-grid.grid-view .titleHolder a img.icon {
    width: 70px;
    height: 70px;
	background-size: 70px 70px;
	margin-left: 20px;
}
#standard-grid.grid-view .swiper-container .thumbLink:hover {
	transform: scale(1.07);
}
#standard-grid.grid-view .swiper-container .thumbLink:hover + .titleHolder p {
	transform: scale(1.07);
	text-shadow: 0 3px 20px rgba(0,0,0, .4);
}

/* Detail theme css*/

.detail-view-cls {
	position: relative;
}

.detail-view-cls .detail-view .swiper-wrapper .img-video {
	width: 100%;
	max-width: 100%;
}

.detail-view-cls .detail-view .swiper-wrapper > .detail-swiper {
	width: 35vw;
	max-width: 100%;
	margin-left: 158px;
}
.detail-view-cls .detail-view .swiper-slide.swiper-slide-active{
	transform: scale(1.06);
  -webkit-transform: scale(1.06);
	transform: perspective(1px) scale(1.06);
}

.detail-view-cls .swiper-container-vertical .detailContent {
	width: calc(65vw - 160px);
	padding: 75px 75px 0;
	box-sizing: border-box;
}

.detail-view-cls .swiper-container-vertical .detailContent .saveIcon {
	width: calc(65vw - 160px);
	padding-right: 0;
	bottom: 160px;
}

.detail-view-cls .swiper-container-vertical .detailContent .saveIcon img {
	padding: 30px 37px;
    border: 0;
    width: 70px;
}

.detail-view-cls .detail-view .swiper-wrapper .titleHolder p {
	display: block;
	text-align: center;
	font-size: 29px;
	height: auto;
	max-height: fit-content;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	overflow: hidden;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
}

.detail-view-cls .detail-view.portrait-view .swiper-wrapper .titleHolder {
	width: 100%;
	position: relative;
	top: -1px;
}

.detail-view-cls .detail-view .swiper-container.swiper-container-vertical {
	padding-top: 75px;
}

.detail-view-cls .detail-view.portrait-view .swiper-wrapper > .detail-swiper {
	width: 475px;
}

.detail-view-cls .swiper-container-vertical .detailContent .ctaHolder {
	padding-bottom: 0;
}

.detail-view-cls > div > h2 {
	top: 276px;
    left: -139px;
    font-weight: bold;
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    height: auto;
	width: 465px;
	-webkit-transform: rotate(270deg);
	-moz-transform: rotate(270deg);
	-o-transform: rotate(270deg);
	font-size: 50px;
}

.detail-view-cls .swiper-container-vertical .detailContent .summaryHolder h4,
.detail-view-cls .swiper-container-vertical .detailContent .ctaHolder {
	padding-right: 0;
	font-size: 42px;
	font-weight: bold;
	max-height: fit-content;
}

.detail-view-cls .swiper-container-vertical .detailContent .ctaHolder p {
	margin-top: 0;
}

.detail-view-cls .swiper-container-vertical .detailContent .summaryHolder p {
	padding-right: 0;
	font-size: 34px;
}

.detail-view-cls .swiper-container-vertical .detailContent .summaryHolder p:nth-child(2) {
	width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
	display: block;
	max-height: min-content;
}

.detail-view-cls .swiper-container-vertical .detailContent .summaryHolder p:last-child {
	display: -webkit-box;
	-webkit-line-clamp: 5;
	overflow: hidden;
	max-height: 343px !important;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
}

.detail-view-cls .detail-view .swiper-wrapper .titleHolder {
	height: 100px;
	position: relative;
	top: -1px;
}
.img-banner {
    height: auto;
}

/* Jumbo view */
.jumbo-view .ctaHolder {
	padding: 10px 0 0 135px;
}
.jumbo-view h2 {
	width: 400px;
	font-size: 42px;
}
.jumbo-view .ctaHolder p,
.jumbo-view .titleHolder p {
	font-size: 36px;
}
.jumbo-view .lock-icon {
	display: inline-block;
    margin-bottom: -2px;
    padding-right: 5px;
    width: 30px;
}
.jumbo-view .img-video {
	max-width: unset !important;
}
.jumbo-view .swiper-container {
	padding: 25px 135px 20px;
}
.jumbo-view .titleHolder a img.icon {
    width: 70px;
    height: 70px;
    background-size: 70px 70px;
}
.jumbo-view .swiper-container {
    height: 590px;
}
.jumbo-view .img-video {
    height: 405px;
}
.jumbo-view .titleHolder {
    height: 135px;
}
.jumbo-view .titleHolder p {
	max-height: 110px;
}
.jumbo-view.portrait-view .swiper-container {
    height: 780px;
}
.jumbo-view.portrait-view .img-video {
    height: 550px;
}
.jumbo-view.portrait-view .titleHolder {
    height: 180px;
}
.jumbo-view.portrait-view .titleHolder p {
    max-height: 160px;
}
.jumbo-view .ctaHolder p {
	margin-bottom: 10px;
}
#overlay + .fix-nav > .nav-view {
	position: relative;
}

.font-black .crossBtn::after, .font-black .crossBtn::before  {
	background-color: black;
}

#overlay.payment  {
	background-color: #F2F2f2;
}

.green-btn{
	background: #4CAF50;
    outline: none;
    border: none;
    color: white;
    font-size: 21px;
    padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
}
.green-btn:hover {
	background-color: #090;
}
.green-btn:disabled {
	background-color: #e7e7e7;
	color: black;
	cursor: not-allowed;
}

/* Close button icon */
.crossBtnIcn {
	position: absolute;
	left: 32px;
	top: 32px;
	width: 32px;
	height: 32px;
	opacity: 0.6;
}
.crossBtnIcn:hover {
	opacity: 1;
}
.crossBtnIcn:before, .crossBtnIcn:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 33px;
	width: 2px;
	background-color: #333;
}
.crossBtnIcn:before {
	transform: rotate(45deg);
}
.crossBtnIcn:after {
	transform: rotate(-45deg);
}

/* Error div*/
.error-div {
	position: fixed;
	top: 0;
	left: 0;
	text-align: center;
	background-color: #f44336;
	color: white;
	padding: 5px;
	width: 100vw;
	z-index: 101;
}

.error-div .crossBtnIcn {
	left: unset;
	right: 15px;
	top: 7px;
}
.error-div .crossBtnIcn:before, .error-div .crossBtnIcn:after {
	background-color: #fff;
}

/* Loader */
.payment-loader {
	height: 100vh;
    width: 100vw;
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
	align-items: center;
	background-color: white;
	opacity: 0.85;
	z-index: 100;
	flex-direction: column;
}

.payment-loader .loader-text {
	color: black;
}
/* Registration wall  */
.logInScreen .btn-email.continue-btn,
.logInScreen .btn-fbIcon.metro {
	text-align: center;
}
.overflow-scroll {
	overflow: scroll;
}
.overflow-scroll-auto {
	overflow: auto;
}
.user-access .logInScreen .subscriberHeader {
	margin: 40px 0;
}
.user-access .logInScreen .subscriberHeader a {
	padding: 30px;
    border-radius: 8px;
    min-width: 600px;
    box-sizing: border-box;
	display: inline-block;
	font-size: 36px;
}
.user-access .logInScreen .subscriberHeader a:hover {
	transform: scale(1.1);
}
.user-access .logInScreen .btn-fbIcon {
	font-size: 36px;
	padding: 25px 30px;
    min-width: 600px;
}
.user-access .logInScreen .btn-fbIcon img{
	width: 60px;
    height: 60px;
    background-size: 60px;
    vertical-align: middle;
    margin-right: 15px;
}
.user-access .logInScreen a.btn-email {
	padding: 30px;
    min-width: 600px;
    box-sizing: border-box;
    font-size: 36px;
}
.user-access .logInScreen a.btn-email .mail-icon{
	background-size: 60px 50px;
	height: 50px;
	width: 60px;
	margin-right: 15px;
    vertical-align: bottom;
}
.user-access .logInScreen p {
	margin-bottom: 60px;
	font-size: 42px;
}
.user-access .logInScreen .termsOfUse {
	position: static;
	margin: 40px 0;
}
.user-access .logInScreen .termsOfUse li a {
	font-size: 30px;
}
.user-access a.crossBtn {
	left: 20px;
}
.user-access a.crossBtn::after,
.user-access a.crossBtn::before {
	content: '';
    width: 40px;
    height: 5px;
}
.user-access a.crossBtn::after {
	transform: translate(8px,-2px) rotate(45deg);
}
.user-access a.crossBtn::before {
	transform: translate(8px,36px) rotate(-45deg);
}
.user-access .bckgrnds .videoBg video {
	height: 100%;
}
.user-access .logInScreen #account-form input,
.user-access .logInScreen #account-form .btnForgot a,
.user-access .logInScreen #account-form button {
	font-size: 26px;
}
.user-access .logInScreen #account-form .btnForgot {
	display: inline-block;
    position: relative;
    margin-top: 30px;
}
.user-access .logInScreen #account-form .btnForgot input {
	margin: 0;
}
.user-access .logInScreen #account-form .btnForgot a {
	min-width: 200px;
	position: absolute;
	margin: 0;
	right: -275px;
    padding: 12px 20px;
}
.user-access .logInScreen #account-form span.btnBox {
	display: inline-block;
	margin-top: 100px;
}
.user-access .logInScreen #account-form span.btnBox button{
	padding: 10px 20px;
	margin:	0 10px;
	min-width: 275px;
}
.user-access .logInScreen #account-form input {
	min-width: 550px;
}
.userLoginCntnt {
	min-height: calc(100vh - 100px);
    display: flex;
	flex-direction: column;
	align-content: center;
    justify-content: center;
}
.user-access .logInScreen a.btn-email .website-icon {
	background-image: url("../images/icons/web_white.png");
}
.user-access .logInScreen a.btn-email .website-icon{
	background-size: 50px 50px;
	height: 50px;
	width: 50px;
	margin-right: 15px;
	vertical-align: bottom;
	display: inline-block;
	vertical-align: middle;
	background-repeat: no-repeat;
}

.user-access .termsOfUse {
	position: relative;
	text-align: center;
}

.termsOfUse ul.mob_info {
	padding: 0;
}
.termsOfUse li {
	padding: 0px 5px;
}
.termsOfUse li.termsVertical {
	font-size: 20px;
}

/* Search section */
.search-section .search-row {
	padding: 50px;
	display: grid;
    grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 50px;
	width: 90%;
}
.search-section .search-row .searchContent {
	width: unset;
    margin: 0;
}
.search-section .search-row .searchContent .titleHolder {
	width: auto;
	display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.search-section .search-row .searchContent .titleHolder p {
	height: auto;
	font-size: 36px;
	text-align: center;
	float: unset;
	width: 100%;
	margin: 1em 0;
}
.search-section .search-row .searchContent .img-video {
	height: 300px;
	padding-bottom: 0px;
}
.search-section .search-row .searchContent .img-video img {
	position: static;
}
.search-section .search-row .searchContent .titleHolder a img.icon {
    width: 70px;
    height: 70px;
	background-size: 70px 70px;
	margin: 1em 0 1em 10px;
}
.search-section .no-search-items{
	display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    font-size: 20px;
}
.simulator-route-container .search-section .no-search-items span{
	margin-bottom: 120px;
}
/* Confirm modal css*/
.confirm-modal {
	background: rgba(0,0,0,0.92);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
	left: 0;
	display: flex;
    align-items: center;
	justify-content: center;
	color: black;
	z-index: 100;
}

.confirm-modal > div {
	background: transparent;
    width: 400px;
    height: 250px;
    border-radius: 15px;
    padding: 15px;
    color: white;
    text-align: center;
}

.confirm-modal p {
	font-size: 23px;
}

.confirm-modal button {
    margin-right: 20px;
    cursor: pointer;
    background-color: white;
    color: black;
    padding: 20px 45px;
    border-radius: 8px;
    outline: none;
    border: none;
	font-size: 22px;
	font-weight: 600;
}

.confirm-modal h3 {
	margin: 10px 0;
}

/* Subscription page */
#overlay.subscribe-page {
	overflow: auto;
}
.subscribeScreen-wrapper {
	padding: 30px 0;
}
.subscribeScreen .sub-item-details h4 {
	margin: 10px 0;
}

.subscribeScreen .subscription-option-div {
	display: flex;
	flex-direction: column;
	align-content: center;
	margin-bottom: 25px;
}

.subscribeScreen .subscription-option-div .trial-text {
	font-size: 22px;
	margin-top: 10px;
}

.subscribeScreen .sub-item-details > div {
	font-size: 22px;
}

.subscribeScreen .sub-item-cancel-button p {
	font-size: 22px;
}
.subscribeScreen span:last-child a.btn-trial {
	margin: 0;
}

.subscribeScreen .subscription-option-div a.btn-trial {
	min-width: 600px;
	margin: 0;
}

.subscribeScreen .sub-item-cancel-button  span > a.btn-trial{
	margin-top: 15px;
}

.subscribeScreen p.subscription-header-text {
	margin-bottom: 15px;
}

.subscribeScreen-wrapper .termsOfUse {
	position: relative;
	text-align: center;
}

.subscribeScreen-wrapper .termsOfUse > ul {
	margin-bottom: 0;
}

.subscribeScreen .sub-unlock-note {
	font-size: 18px;
}

.subscribeScreen  .restore-purchase {
	margin-top: 60px;
}

.subscribeScreen  .restore-purchase > a.btn-trial{
	min-width: auto;
    padding: 18px 40px;
}

.subscribeScreen-wrapper .disclaimer {
	padding: 30px 50px;
    text-align: center;
    font-size: 18px;
}

.subscribeScreen-wrapper .subscription-cntnt {
	min-height: calc(100vh - 190px);
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subscribeScreen-wrapper .iapTitle {
	text-align: center;
	margin-bottom: 5px;
	font-size: 30px;
}

.icon-hollow_arrow.backBtnSpan {
	position: absolute;
	cursor: pointer;
}

/* show navbar In sim live, live*/
.transparent .slider-box.nav-view {
	opacity: 0.7;
	z-index: 201;
}

#overlay {
	top: 0;
}

.with-modal #nav-bar {
	position: fixed;
	top: 0;
	width: 100%;
}
.videoFull .icon-hollow_arrow.video_back{
	position: absolute;
}

/* Payment confirmation page */
.payment-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-page img {
	width: 200px;
	height: auto;
}

.payment-page .dur-price {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-page .pay-cnf-msg {
	font-size: 35px;
}

.payment-page  .redirect-text, .payment-page  .redirect-bck {
	font-size: 20px;
}

.payment-page  .redirect-bck {
	color: blue;
	cursor: pointer;
}

.image-scroll .swiper-slide.image-full {
	background-position: center center;
}

.icon-hollow_arrow a.backBtn{
	z-index: 100;
    background-image: url(../images/back.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
	border-radius: 4px;
	width: 50px;
    height: 35px;
}

span.icon-hollow_arrow:before,
span.icon-hollow_arrow:after {
	content: unset;
}

span.icon-hollow_arrow{
	position: absolute;
	left: 20px;
	top: 20px;
}


/*vjs error display in the middle*/
.vjs-error .vjs-error-display .vjs-modal-dialog-content {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000;
	font-size: 16px;
}

/* Feed error page*/
.feed-error {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: black;
}

.feed-error h1 {
	text-align: center;
	font-size: 45px;
}

.no-content-div {
	margin-top: 0 !important;
	background: black;
	height: 100vh;
}

#account-form input,
#account-form button,
.termsOfUse a,
.subscribeScreen-wrapper a {
	border: none;
	outline: none;
}

/* Style for data-privacy */
.data-privacy {
	padding: 50px;
	background-color: #ddd6d6;
	height: 100vh;
	box-sizing: border-box;
	color: #fff;
	overflow-y: scroll;
}
.data-privacy hr{
	background-color: #fff;
    height: 2px;
}
.data-privacy .privacy-header {
	text-align: center;
	color: #000;
	display: flex;
    align-items: center;
    justify-content: center;
}
.data-privacy .privacy-header img {
	height: 60px;
	margin-right: 30px;
}
.data-privacy .privacy-header span {
	font-size: 34px;
}
.data-privacy .privacy-item {
	padding: 10px;
	display: grid;
	grid-template-columns: 100px auto 300px;
	align-items: center;
}
.data-privacy .privacy-item p {
	padding: 0 20px 0 40px;
}
.data-privacy .contine-button,
.data-privacy .privacy-item a {
	padding: 15px 30px;
    border-radius: 4px;
	color: #fff;
	cursor: pointer;
	border: none;
	text-align: center;
}
.data-privacy .btn-wrapper {
	text-align: center;
}
.data-privacy .contine-button {
	width: 300px;
	font-size: 28px;
	margin-top: 40px;
}
.data-privacy .contine-button[disabled] {
	cursor: not-allowed;
	opacity: .7;
}

/* Below two css are to hide additional video info from videoJs player*/
.vjs-control-bar, .vjs-track-settings-colors, .vjs-hidden, .vjs-control-text {
	visibility: hidden;
}
.vjs-text-track-display {
	display: none;
}


/* website login page */
#website-login {
	position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    height: 100vh;
    background: black;
	z-index: 200;
	display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
	align-items: center;
	padding: 40px 0;
}

#website-login .website-login-cntnt {
	text-align: center;
}

#website-login a {
	cursor: pointer;
	color: white;
	text-decoration: none;
}
#website-login .logoHolder{
	padding: 0;
	margin-bottom: 40px;
}

#website-login .redirect-message {
	font-size: 0.7em;
}

#previewVideo .vjs-loading-spinner {
	display: none !important;
}