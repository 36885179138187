/* This css file is only for WEB, do not include any simulator and CTV related css here */
/* variables */
body {
	--nav-height: 70px;
	--nav-z-index: 100;
	--nav-pri-color: #fff;
}
/* variables */

/* Web Navbar */
#web-nav-bar {
	background-color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
	height: var(--nav-height);
	position: sticky;
	top: 0;
	z-index: var(--nav-z-index);
}
#web-nav-bar .left-links,
#web-nav-bar .right-links {
	display: flex;
    align-items: center;
}
#web-nav-bar .left-links img {
	max-height: 30px;
	margin-right: 30px;
	cursor: pointer;
}
#web-nav-bar-mobile .nav-logo {
	max-height: 30px;
	max-width: 120px;
}
#web-nav-bar-mobile .missing-nav-logo {
	max-height: 30px;
	font-size: 24px;
}
#web-nav-bar .left-links a {
	margin: 0 10px;
	font-size: 18px;
	color: #dcdcdc;
	outline: none;
}
#web-nav-bar .navbar-search,
#web-nav-bar .navbar-settings img{
	margin-left: 20px;
	height: 24px;
	cursor: pointer;
}
#web-nav-bar .left-links a.active {
    color: var(--nav-pri-color);
	font-size: 20px;
}
#web-nav-bar .navbar-search.active img,
#web-nav-bar .navbar-settings.active img{
	height: 26px;
}
#web-nav-bar .web-search-box.closed-search-box {
	width: 40px;
}
#web-nav-bar-mobile .web-search-box.closed-search-box {
	width: 36px;
}
#web-nav-bar-mobile .web-search-box,
#web-nav-bar .web-search-box {
	position: relative;
	width: 210px;
	transition: width 1s;
}
#web-nav-bar-mobile .web-search-box {
	margin-right: 10px;
}
#web-nav-bar-mobile .web-search-box input,
#web-nav-bar .web-search-box input {
	width: 100%;
  height: 35px;
	padding-left:45px; 
	border-radius:5px; 
	background-size:20px; 
	background-position:15px;
	border: 2px solid var(--nav-pri-color);
	outline:none;
	color: var(--nav-pri-color);
	background-image:url('../../src/images/icons/search-white.png');
	background-repeat:no-repeat;
	background-color: #000;
	vertical-align: middle;
	box-sizing: border-box;
}
#web-nav-bar-mobile .web-search-box input {
	padding-left: 30px;
	background-size: 16px; 
	background-position: 8px;
}
#web-nav-bar-mobile .web-search-box input::placeholder,
#web-nav-bar .web-search-box input::placeholder {
	color: var(--nav-pri-color);
}
#web-nav-bar-mobile .web-search-box .close-search-bt,
#web-nav-bar .web-search-box .close-search-bt{
	position: absolute;
    right: 10px;
    bottom: 2px;
    cursor: pointer;
}
.web-route-container .with-modal.subscription-section #web-nav-bar-mobile-wrapper,
.web-route-container .with-modal.subscription-section #web-nav-bar {
	/* to hide navbar for subscription from settings */
	display: none;
}
#web-nav-bar .missing-nav-logo {
	font-size: 26px;
	margin-right: 30px;
	cursor: pointer;
}
#web-nav-bar-mobile-wrapper {
	height: var(--nav-height);
}
#web-nav-bar-mobile,
#web-nav-bar-mobile-wrapper .section-menu-mobile {
	background-color: #000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;
	position: fixed;
	top: 70px;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	z-index: var(--nav-z-index);
}
#web-nav-bar-mobile {
	top: 0;
	flex-direction: row;
	justify-content: space-between;
  padding: 0 20px;
	height: var(--nav-height);
}
#web-nav-bar-mobile .navbar-search,
#web-nav-bar-mobile .navbar-settings img{
	margin-right: 10px;
	height: 24px;
}
#web-nav-bar-mobile .right-links {
	display: flex;
	align-items: center;
}
#web-nav-bar-mobile-wrapper .section-menu-mobile a{
	font-size: 18px;
	color: #dcdcdc;
	margin-bottom: 10px;
}
#web-nav-bar-mobile-wrapper .section-menu-mobile a.active {
	color: var(--nav-pri-color);
	font-size: 20px;
}

.web-route-container #overlay.user-access.no-close-btn,
.web-route-container .content-box .colorBg,
.web-route-container .content-box .videoBg,
.web-route-container .content-box .imageBg,
.web-route-container .mediaOverlay.section-level-content,
.web-route-container .content-box .gradientBg,
.web-route-container #fullscreen-grid,
.web-route-container #previewSection {
	top: calc(var(--nav-height) - 1px) !important;
	height: calc(100% - var(--nav-height) + 1px);
}
.web-route-container .content-box .videoBg video {
	height: 100%;
}
.web-route-container .with-modal .image-view,
.web-route-container .mediaOverlay {
	/* to hide fixed navbar */
	z-index: calc(var(--nav-z-index) + 10);
}
.web-route-container.web-route-container-mobile .mediaOverlay.section-level-content {
	/* to show fixed navbar in section level for mobile*/
	z-index: var(--nav-z-index);
}
.web-route-container #overlay.user-access.no-close-btn {
	z-index: calc(var(--nav-z-index) - 10);
}
.web-route-container .icon-hollow_arrow.backBtnSpan {
	/* move backbutton down */
	top: calc(var(--nav-height) + 20px) !important;
}
.web-route-container .with-modal.subscription-section .icon-hollow_arrow.backBtnSpan,
.web-route-container #overlay.user-access .icon-hollow_arrow.backBtnSpan {
	/* No need on popover move backbutton down */
	top: 20px !important;
}
.web-route-container .user-access .userLoginCntnt {
	margin-top: 0 !important;
}
.web-route-container .search-section {
	padding-top: 0;
}


 /* For 50% reduction - Standard theme*/
.web-route-container .themeLogo .logoHolder img,
.web-route-container .logoHolder img {
	max-height: 50px;
}
.web-route-container #standard-grid.grid-view .ctaHolder,
.web-route-container #standard-grid.grid-view h2 {
	font-size: 21px;
	padding: 25px 0 10px 70px;
}
.web-route-container #standard-grid.grid-view .titleHolder p,
.web-route-container #standard-grid.nothumbBox.grid-view .titleHolder p {
	font-size: 18px;
}
.web-route-container .themeLogo .logoHolder.smallImg img,
.web-route-container .logoHolder.smallImg img {
	max-height: 48px;
}
.web-route-container .banner .swiper-container {
	height: auto;
}
.web-route-container #standard-grid.grid-view .titleHolder a img.icon {
	width: 35px;
	height: 35px;
	background-size: 35px 35px;
	margin-left: 10px;
}
.web-route-container #standard-grid.grid-view .swiper-container {
    padding: 10px 70px 0;
    margin-bottom: 10px;
}
.web-route-container #standard-grid.grid-view .titleHolder {
    margin-top: 18px;
}
.web-route-container .lock-icon {
    max-width: 20px;
}
.web-route-container #standard-grid.grid-view .titleHolder a {
	align-self: flex-start;
}

/* For 50% reduction - Spotlight theme*/
.web-route-container #spotlight-grid .spotlight.grid-view > *:first-child, 
.web-route-container #fullscreen-grid .spotlight.grid-view > *:first-child {
	font-size: 21px;
}
.web-route-container #spotlight-section > #previewSection > #previewDescription .previewTitle {
	font-size: 29px;  /*Original 38px*/
}
.web-route-container #spotlight-section > #previewSection > #previewDescription .previewSubtitle,
.web-route-container #spotlight-section > #previewSection > #previewDescription #previewSummary{
	font-size: 20px; /*Original 23px*/
}
.web-route-container #spotlight-grid {
	height: calc(100vh - 220px - 150px);
}
.web-route-container .non-section-content #spotlight-grid {
    height: calc(100vh - 220px - 200px);
}
.web-route-container .section-content #spotlight-section > #previewSection > #previewDescription {
	top: 10px;
}
.web-route-container .non-section-content #spotlight-section > #previewSection > #previewDescription {
	top: 70px;
}
.web-route-container #spotlight-section > #previewSection > #previewDescription #previewSummary {
	margin-top: 10px;
}

/* For 50% reduction - Fullscreen theme*/
.web-route-container #fullscreen-grid .spotlight.grid-view > .swiper-container div.titleHolder p {
	font-size: 16px;
}

/* For 50% reduction - Jumbo theme*/
.web-route-container .jumbo-view .img-video {
	height: 0;
	padding-bottom: 56.6%;
}
.web-route-container .jumbo-view.portrait-view .img-video {
	height: 0;
	padding-bottom: 133%;
}
.web-route-container .jumbo-view .ctaHolder p,
.web-route-container .jumbo-view .titleHolder p {
	font-size: 16px;
}
.web-route-container .jumbo-view h2 {
	width: 225px;
    font-size: 21px;
	top: 0;
	left: 0;
	transform: rotate(270deg) translate(-112px, -35px);
}
.web-route-container .jumbo-view .ctaHolder:not(.noTitle) + h2 {
	top: 66px;
}
.web-route-container .jumbo-view .titleHolder {
	height: 68px;
}
.web-route-container .jumbo-view .titleHolder a img.icon {
	width: 35px;
	height: 35px;
	background-size: 35px 35px;
}
.web-route-container .jumbo-view .swiper-container {
	min-height: 280px;
	height: auto;
	padding-top: 5px;
}
.web-route-container .jumbo-view.portrait-view .titleHolder {
	height: 90px;
}
.web-route-container .jumbo-view.portrait-view .swiper-container {
	height: auto;
	min-height: 350px;
}
.web-route-container .jumbo-view {
	position: relative;
}
.web-route-container .jumbo-view.portrait-view h2 {
	/* top	: 485px; */
}
.web-route-container .jumbo-view .ctaHolder p {
    margin-bottom: 0;
}
.web-route-container .jumbo-view .ctaHolder {
	height: auto;
	padding-bottom: 10px;
}

/* For 50% reduction - Detail theme*/
.web-route-container .detail-view-cls .detail-view .swiper-wrapper > .detail-swiper {
	width: 26vw;
}
.web-route-container .detail-view-cls .detail-view .swiper-wrapper .titleHolder {
	height: 60px;
}
.web-route-container .detail-view-cls .detail-view.portrait-view .swiper-wrapper .titleHolder {
	height: 70px;
}
.web-route-container .detail-view-cls .detail-view .swiper-wrapper .titleHolder p {
	font-size: 18px;
}
.web-route-container .detail-view-cls .detail-view.portrait-view .swiper-wrapper .titleHolder p {
	font-size: 16px;
}
.web-route-container .detail-view-cls .swiper-container-vertical .detailContent,
.web-route-container .detail-view-cls .swiper-container-vertical .detailContent .saveIcon {
	width: calc(74vw - 125px);
}
.web-route-container .detail-view-cls .swiper-container-vertical .detailContent .summaryHolder h4,
.web-route-container .detail-view-cls .swiper-container-vertical .detailContent .ctaHolder {
	font-size: 30px;  /*Original 40px*/
}
.web-route-container .detail-view-cls .swiper-container-vertical .detailContent .ctaHolder img {
	max-width: 30px;
}
.web-route-container .detail-view-cls > div > h2 {
	font-size: 38px;    /*Original 50px*/
	top: 38px;
}
.web-route-container .detail-view-cls {
	--detail_portait_width: 18vw;  /*Original 28vw*/
}
.web-route-container .detail-view-cls .detail-view.portrait-view .swiper-wrapper > .detail-swiper {
	width: var(--detail_portait_width);   /*Original 530px */
}

.web-route-container .detail-view-cls .detail-view .swiper-container.swiper-container-vertical,
.web-route-container .detail-view-cls .swiper-container-vertical .detailContent {
	padding-top: 70px;
}
.web-route-container .detail-view h2 {
	width: 460px;
	transform: rotate(270deg) translate(-240px, 0);
}
.web-route-container .detail-view-cls .swiper-container-vertical .detailContent .summaryHolder p {
	font-size: 25px;
}
.web-route-container .detail-view-cls .swiper-container-vertical .detailContent .summaryHolder p:last-child {
	-webkit-line-clamp: 8;
}
.web-route-container .detail-view-cls .swiper-container-vertical .detailContent .saveIcon img {
	padding: 15px 16px;
    border: 0;
    width: 35px;
}


/* Search Ssection 50% thumbnails */
.web-route-container .search-section .search-row {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.web-route-container .search-section .search-row .searchContent .img-video {
	padding-bottom: 56.6%;
	height: 0;
} 
.web-route-container .search-section .search-row .searchContent .img-video video{
	object-position: right;
}
.web-route-container .search-section .search-row .searchContent .img-video img {
	position: absolute;
}
.web-route-container .search-section .search-row .searchContent .titleHolder p {
	font-size: 18px;
}
.web-route-container .search-section .search-row .searchContent .titleHolder a img.icon {
	width: 30px;
    height: 30px;
    background-size: 30px 30px;
}

.web-route-container .video-inlineMenu {
	--video_inline_item_width: 12vw;
	--video_inline_item_height: calc(var(--video_inline_item_width) * 0.5625);
}
.web-route-container .video-inlineMenu .video-inlineMenuItem .title p {
	font-size: 18px;
	line-height: normal;
	display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
}
.web-route-container .video-inlineMenu .video-inlineMenuItem .title {
	padding-bottom: 5px;
}
.web-route-container .video-inlineMenu .menu.not-visible {
	height: 0 !important;
}
.web-route-container .video-inlineMenu.closingNow .menu {
	height: 0;
}

/* Next up UI*/
.web-route-container #next-video.prevThumbnail {
	width: 290px;
}

.web-route-container .prevThumbnail img {
	height: 129px;
    width: 290px;
}

/* Subscribe screen */
.web-route-container .user-access .logoHolder.smallImg img,
.web-route-container .subscribeScreen-wrapper .logoHolder.smallImg img {
	max-height: 60px;
}
.web-route-container .subscribeScreen a.btn-trial {
	min-width: 400px;
	font-size: 19px;
	padding: 14px 20px;
	margin: 10px 0;
}
.web-route-container .subscribeScreen-wrapper .iapTitle,
.web-route-container .subscribeScreen p {
	font-size: 26px;
}
.web-route-container .subscribeScreen p.subscription-header-text {
	margin-bottom: 9px;
}
.web-route-container .subscribeScreen .item-button a.btn-trial {
	margin-bottom: 20px;
}
.web-route-container .subscribeScreen .subscription-option-div .trial-text {
	margin-top: 0;
	font-size: 18px;
}
.web-route-container .subscribeScreen .sub-unlock-note {
	font-size: 22px;
	margin-top: -10px;
}
.web-route-container .subscribeScreen-wrapper .disclaimer {
	font-size: 15px;
}
.web-route-container .termsOfUse li a {
	font-size: 19px;
}
.web-route-container .subscribeScreen-wrapper .disclaimer {
	padding-bottom: 40px;
}
.web-route-container .subscribeScreen .restore-purchase {
	margin-top: 20px;
}

/*  Registration screen*/
.web-route-container .user-access .logInScreen p {
	font-size: 26px;
}
.web-route-container .user-access .logInScreen a.btn-email, 
.web-route-container .user-access .logInScreen .subscriberHeader a, 
.web-route-container .user-access .logInScreen .btn-fbIcon {
	font-size: 22px;
    min-width: 400px;
    padding: 18px;
}
.web-route-container .user-access .logInScreen a.btn-email .mail-icon {
    background-size: 35px 27px;
    height: 27px;
    width: 35px;
    vertical-align: middle;
}
.web-route-container .user-access .logInScreen .btn-fbIcon img,
.web-route-container .user-access .logInScreen a.btn-email .website-icon {
    width: 32px;
    height: 32px;
    background-size: 32px;
}

.web-route-container .user-access .logInScreen #account-form input,
.web-route-container .user-access .logInScreen #account-form .btnForgot a,
.web-route-container .user-access .logInScreen #account-form button {
	font-size: 22px;
}
.web-route-container .user-access .logInScreen #account-form span.btnBox button {
    padding: 8px 10px;
    margin: 0 7px;
	min-width: 200px;
	font-size: 19px;
}
.web-route-container .user-access .logInScreen #account-form .btnForgot a {
	padding: 8px 10px;
	right: -249px;
    top: 4px;
}
.web-route-container .user-access .logInScreen #account-form input {
	min-width: 604px;
}
.web-route-container .user-access .logInScreen #account-form span.btnBox {
	margin-top: 30px;
	padding-bottom: 20px;
}
.web-route-container .user-access .logInScreen p.user-access-heading {
	margin-bottom: 30px;
}

.web-route-container .data-privacy,
.web-route-container .data-privacy .contine-button {
	font-size: 22px;
}
.web-route-container .data-privacy .privacy-header span {
	font-size: 30px;
}
.web-route-container .data-privacy .privacy-header img {
	height: 55px;
}
.web-route-container .data-privacy .privacy-item a {
	padding: 10px 20px;
}
.web-route-container .data-privacy .contine-button {
	padding: 12px 20px;
}

.web-route-container .video-inlineMenu .button-toggle img {
	height: 40px;
	width: 40px;
}

.web-route-container .data-privacy .switch-slider-wrapper {
    width: 94px;
	height: 43px;
}

.web-route-container .data-privacy .switch-slider-wrapper .switch-slider:before {
    width: 35px;
    height: 35px;
}

.web-route-container .data-privacy .contine-button {
	width: auto;
	min-width: 250px;
}

.web-route-container .logIn-container .SettingScreen {
	margin: 5px 30px 5px 0;
	width: 32%;
}
.web-route-container .SettingScreen a {
	font-size: 26px;
	margin: 15px 0;
}

.web-route-container .userLoginCntnt {
	min-height: calc(100vh - 100px);
}

.web-route-container .no-close-btn .userLoginCntnt {
	min-height: calc(100vh - 165px);
}

.web-route-container .invalidScreen .inv-head {
	font-size: 21px;
}

.web-route-container .user-access .subscribeScreen-wrapper {
	margin-top: 0 !important;
	padding-top: 0;
	min-height: calc(100vh - 30px);
}

.web-route-container .user-access.no-close-btn .subscribeScreen-wrapper {
	min-height: calc(100vh - 100px);
}

.web-route-container .rePassword .reContent a {
    min-width: 180px;
    padding: 14px;
    margin: 10px 0;
    font-size: 19px;
}
.web-route-container .rePassword .reContent p {
	font-size: 22px;
}

.web-route-container .video-title {
	width: auto;
    height: auto;
    line-height: initial;
    max-width: 330px;
    padding: 10px 10px 14px;
    min-width: 210px;
}
.web-route-container .video-title p {
	margin: 0;
	font-size: 21px;
}
.web-route-container a.btn-dismiss {
	min-width: 180px;
    padding: 9px 15px;
    font-size: 23px;
}
.web-route-container .invalidContent p {
	font-size: 21px;
}
.web-sponsor p{
	font-size: 24px;
}

.web-feed-err h1 {
	font-size: 24px;
}

.web-route-container .payment-page img {
	width: 100px;
}
.web-route-container .payment-page .pay-cnf-msg {
	font-size: 26px;
}
.web-route-container .payment-page .pay-title {
	font-size: 22px;
}
.web-route-container .payment-page .dur-price {
	font-size: 19px;
}
.web-route-container .payment-page .redirect-text,
.web-route-container .payment-page .redirect-bck {
    font-size: 18px;
}
.web-route-container .subscribeScreen .sub-item-cancel-button span > a.btn-trial {
	min-width: 220px;
}
.web-route-container .subscribeScreen .sub-item-details h4 {
	font-size: 26px;
	margin-bottom: 0;
}
.web-route-container .subscribeScreen .sub-item-details .sub-item-msg {
	font-size: 22px;
}
.web-route-container .subscribeScreen .sub-item-details > div {
	font-size: 19px;
}
.web-route-container .confirm-modal h3 {
	font-size: 26px;
	margin-bottom: 0;
}
.web-route-container .confirm-modal p {
	font-size: 22px;
	margin-top: 18px;
}
.web-route-container .confirm-modal > div {
	width: 600px;
}
.web-route-container .confirm-modal button {
	font-size: 19px;
    padding: 14px;
	min-width: 170px;
	font-family: 'San-Francisco-Medium', '.SFUIDisplay';
}
.web-route-container .subscribeScreen .sub-item-cancel-button p {
	font-size: 19px;
}

/* --------------------------------------------------- */
/* CSS for video grid theme for mobile suppport STARTS */
/* ----------------------------------------------------*/
.video-grid {
	height: calc(100% - var(--nav-height))
}
.video-grid-content .swiper-slide {
	width: 180px;
}
.video-grid-content .swiper-slide .img-video {
	border-radius: 8px;
	padding-bottom: 56.6%;
	background-color: #000 !important;
}
.video-grid-content .swiper-slide .img-video .img-thumb {
	border-radius: 8px;
}
.video-grid-theme {
	padding: 4px 3% 3% 3%;
	box-sizing: border-box;
}
.video-grid-theme .colorBg {
	background-color: #fff;
}
.video-grid-content .ctaHolder,
.video-grid-content h2 {
	font-size: 20px;
	padding-left: 0;
	margin-bottom: 15px;
	color: #000;
}
.video-grid-content .ctaHolder p{
	margin: 0;
}
.video-grid-content .swiper-slide .titleHolder p {
	font-size: 16px;
	-webkit-line-clamp: 2;
	color: #000;
	display: -webkit-box;
  -webkit-box-orient: vertical;
}
.video-grid .with-modal .icon-hollow_arrow.backBtnSpan {
	position: relative !important;
	left: 3%;
	font-size: unset;
	top: 10px !important;
}
.web-route-container .icon-hollow_arrow .backBtn-mobile img,
.web-route-container .user-access .icon-hollow_arrow.backBtnSpan .backBtn-mobile img,
.web-route-container .with-modal .icon-hollow_arrow.backBtnSpan .backBtn-mobile img {
	height: 20px;
	width: 20px;
	background-color: #fff;
	padding: 5px;
	border-radius: 4px;
	margin-bottom: 4px;
}
.web-route-container-mobile span.icon-hollow_arrow {
	font-size: 0px;
}
.video-grid .search-section .no-search-items span {
	color: #000;
}

.web-route-container .mobile-view .logIn-container {
	padding: 20px;
	height: 100%;
	box-sizing: border-box;
}
.web-route-container .mobile-view .SettingScreen {
	width: 100% !important;
	height: 100%;
	margin: 0 !important;
	float: unset !important;
}
.web-route-container .mobile-view .SettingScreen ul{
	padding-left: 0px;
}
.web-route-container-mobile #overlay-video .vjs-control-bar {
	display: flex !important;
}
.web-route-container-mobile .videoFull .video-js,
.web-route-container-mobile .videoFull .video-js .vjs-tech {
	width: 100% !important;
  height: 100% !important;
}
.web-route-container-mobile .videoFull iframe {
	height: 100% !important;
	width: 100% !important;
}
.web-route-container-mobile .data-privacy {
	/* background-color: #fff; */
	/* color: black; */
	padding: 60px 20px 20px 20px;
}
.web-route-container-mobile .mobile-view .bckgrnds .videoBg video {
	object-position: center;
}
.web-route-container-mobile .data-privacy .privacy-header {
	flex-direction: column;
}
.web-route-container-mobile .data-privacy .privacy-header img {
	height: 50px !important;
  margin: 0 0 10px 0;
}
.web-route-container-mobile .data-privacy .privacy-item {
	display: flex;
	align-items: center;
}
.web-route-container-mobile .data-privacy .switch-slider-wrapper {
	width: 40px !important;
	height: 8px !important;
	font-size: 8px !important;
	display: inline-table;
	margin-right: 20px;
}
.web-route-container-mobile .data-privacy .switch-slider-wrapper input:checked + .switch-slider:before {
	transform: translateX(20px) !important;
}
.web-route-container-mobile .data-privacy .switch-slider-wrapper .switch-slider:before {
	left: 0px;
	bottom: -3px;
	width: 20px !important;
	height: 20px !important;
	box-shadow: 0px 1px 8px #888888;
}
.web-route-container-mobile .data-privacy .privacy-item a {
	padding: 0px;
}
.web-route-container-mobile .data-privacy hr {
	height: 0;
}
.web-route-container-mobile .data-privacy .contine-button {
	/* color: black; */
}
.web-route-container-mobile .thumbLink {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.web-route-container-mobile .banner .swiper-container {
	padding: 0 0 10px 0;
}
.web-route-container-mobile .banner .swiper-pagination {
	height: 20px;
}
.web-route-container-mobile .banner .swiper-slide img,
.web-route-container-mobile .banner .swiper-slide {
	border-radius: 0;
}
.web-route-container-mobile .ctaHolder p:nth-child(3) {
	color: gray;
	font-size: 12px;
}
.web-route-container-mobile .lock-icon {
	width: 18px;
	vertical-align: sub;
	opacity: .5;
	padding: 0;
	margin: 0;
}
/* ------------------------------------------------- */
/* CSS for video grid theme for mobile suppport ENDS */
/* --------------------------------------------------*/

#hover-div {
	z-index: -1;
    position: fixed;
    background: white;
    height: 200px;
    width: 100vw;
    bottom: 0;
    left: 0;
}
.web-route-container .bckgrnds .videoBg video {
	height: auto;
	min-height: 100vh;
}
