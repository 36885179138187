/* This css file is only for WEB, do not include any simulator and CTV related css here */
@media screen and (max-width: 1600px) {
    .web-route-container #standard-grid.grid-view .ctaHolder,
    .web-route-container #standard-grid.grid-view h2 {
        font-size: 18px;
        padding: 15px 0 10px 50px;
    }
    .web-route-container #standard-grid.grid-view .swiper-container {
        padding: 10px 50px 0;
    }
    .web-route-container #standard-grid.grid-view .titleHolder a img.icon {
        width: 30px;
        height: 30px;
        background-size: 30px 30px;
    }
    .web-route-container #standard-grid.grid-view .titleHolder p,
    .web-route-container #standard-grid.nothumbBox.grid-view .titleHolder p {
        font-size: 16px;
    }

    .web-route-container #spotlight-section > #previewSection > #previewDescription .previewTitle {
        font-size: 24px;
    }
    .web-route-container #spotlight-section > #previewSection > #previewDescription .previewSubtitle,
    .web-route-container #spotlight-section > #previewSection > #previewDescription #previewSummary{
        font-size: 16px;
    }
    .web-route-container #spotlight-grid .spotlight.grid-view > *:first-child, 
    .web-route-container #fullscreen-grid .spotlight.grid-view > *:first-child {
        font-size: 16px;
    }
    .web-route-container #spotlight-grid {
        height: calc(100vh - 220px - 100px);
    }
    .web-route-container .non-section-content #spotlight-grid {
        height: calc(100vh - 220px - 150px);
    }
    .web-route-container #fullscreen-grid .spotlight.grid-view > .swiper-container div.titleHolder p {
        font-size: 13px;
        margin-top: 15px;
    }
    .web-route-container #fullscreen-grid .spotlight.grid-view > * {
        padding: 15px 40px 0;
    }
    

    .web-route-container .detail-view-cls .swiper-container-vertical .detailContent .summaryHolder p:last-child {
        -webkit-line-clamp: 7;
    }
    .web-route-container .detail-view-cls > div > h2 {
        font-size: 32px;
        left: -170px;
    }
    .web-route-container .detail-view-cls .swiper-container-vertical .detailContent .summaryHolder h4,
    .web-route-container .detail-view-cls .swiper-container-vertical .detailContent .ctaHolder {
        font-size: 26px;
    }
    .web-route-container .detail-view-cls .swiper-container-vertical .detailContent .summaryHolder p {
        font-size: 22px;
    }
    .web-route-container .detail-view-cls .detail-view .swiper-wrapper > .detail-swiper {
        margin-left: 116px;
    }
    .web-route-container .detail-view-cls .swiper-container-vertical .detailContent,
    .web-route-container .detail-view-cls .swiper-container-vertical .detailContent .saveIcon {
        width: calc(74vw - 100px);
    }

    .web-route-container .search-section .search-row .searchContent .titleHolder p {
        font-size: 15px;
    }
    .web-route-container .search-section .search-row .searchContent .titleHolder a img.icon {
        width: 24px;
        height: 24px;
        background-size: 24px 24px;
    }

    .web-route-container .video-inlineMenu .video-inlineMenuItem .title p {
        font-size: 15px;
        line-height: normal;
    }
    .web-route-container #next-video.prevThumbnail {
        width: 215px;
    }
    .web-route-container .prevThumbnail img {
        height: 100px;
        width: 215px;
    }
    .web-route-container .prevThumbnail p {
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        -webkit-box-orient: vertical;
        height: auto;
    }

    .web-route-container .subscribeScreen-wrapper .iapTitle, .web-route-container .subscribeScreen p {
        font-size: 23px;
    }
    .web-route-container .subscribeScreen a.btn-trial {
        min-width: 350px;
        font-size: 16px;
        padding: 12px 15px;
        margin: 7px 0;
    }
    .web-route-container .subscribeScreen .subscription-option-div .trial-text {
        font-size: 14px;
    }
    .web-route-container .subscribeScreen .subscription-option-div {
        margin-bottom: 13px;
    }
    .web-route-container .subscribeScreen-wrapper .disclaimer, .web-route-container .termsOfUse li a {
        font-size: 13px;
    }
    .web-route-container .subscribeScreen .sub-unlock-note {
        font-size: 20px;
        margin-top: -6px;
    }
    .web-route-container .subscribeScreen .restore-purchase {
        margin-top: 10px;
    }

    .web-route-container .user-access .logInScreen p {
        font-size: 22px;
    }
    .web-route-container .user-access .logInScreen a.btn-email, 
    .web-route-container .user-access .logInScreen .subscriberHeader a, 
    .web-route-container .user-access .logInScreen .btn-fbIcon {
        font-size: 18px;
        min-width: 370px;
        padding: 14px;
    }
    .web-route-container .user-access .logInScreen a.btn-email .mail-icon {
        background-size: 30px 24px;
        height: 24px;
        width: 30px;
    }
    .web-route-container .user-access .logInScreen .btn-fbIcon img,
    .web-route-container .user-access .logInScreen a.btn-email .website-icon {
        width: 27px;
        height: 27px;
        background-size: 27px;
    }
    .web-route-container .user-access .logInScreen #account-form input,
    .web-route-container .user-access .logInScreen #account-form .btnForgot a,
    .web-route-container .user-access .logInScreen #account-form button {
        font-size: 18px;
    }
    .web-route-container .user-access .logInScreen #account-form span.btnBox button {
        min-width: 162px;
        font-size: 15px;
        margin: 0 6px;
    }
    .web-route-container .user-access .logInScreen #account-form input {
        min-width: 486px;
    }
    .web-route-container .data-privacy,
    .web-route-container .data-privacy .contine-button {
        font-size: 20px;
    }
    .web-route-container .data-privacy .switch-slider-wrapper .switch-slider:before {
        width: 30px;
        height: 30px;
    }
    .web-route-container .data-privacy .switch-slider-wrapper {
        width: 80px;
        height: 38px;
    }
    .web-route-container .data-privacy .contine-button {
        width: auto;
        min-width: 220px;
    }
    .web-route-container .data-privacy .privacy-item {
        grid-template-columns: 100px auto 250px;
    }
    .web-route-container .data-privacy .switch-slider-wrapper input:checked + .switch-slider:before {
        transform: translateX(40px);
    }
    .web-route-container .video-title {
        min-width: 180px;
        max-width: 230px;
        padding: 8px 8px 12px;
    }
    .web-route-container .video-title p {
        font-size: 17px;
    }
    .web-route-container a.btn-dismiss {
        min-width: 170px;
        padding: 8px 10px;
        font-size: 20px;
    }
    .web-route-container .invalidContent p {
        font-size: 18px;
    }
    .web-sponsor p{
        font-size: 19px;
    }

    .web-route-container .payment-page .pay-cnf-msg {
        font-size: 23px;
    }
    .web-route-container .payment-page .pay-title {
        font-size: 20px;
    }
    .web-route-container .payment-page .dur-price {
        font-size: 17px;
    }
    .web-route-container .payment-page .redirect-text,
    .web-route-container .payment-page .redirect-bck {
        font-size: 16px;
    }
    .web-route-container .subscribeScreen .sub-item-details h4 {
        font-size: 23px;
    }
    .web-route-container .subscribeScreen .sub-item-details .sub-item-msg {
        font-size: 20px;
    }
    .web-route-container .subscribeScreen .sub-item-details > div {
        font-size: 17px;
    }
    .web-route-container .confirm-modal h3 {
        font-size: 23px;
    }
    .web-route-container .confirm-modal p {
        font-size: 20px;
    }
    .web-route-container .confirm-modal button {
        font-size: 17px;
        padding: 12px;
        min-width: 150px;
    }
    .web-route-container .subscribeScreen .sub-item-cancel-button p {
        font-size: 17px;
    }
    .web-route-container .rePassword .reContent a {
        font-size: 17px;
        padding: 12px;
        min-width: 150px;
    }
    .web-route-container .rePassword .reContent p {
        font-size: 20px;
    }
}

@media screen and (max-width: 1536px) {
    .web-route-container .jumbo-view h2 {
        font-size: 18px;
    }
    .web-route-container .jumbo-view .titleHolder p {
        font-size: 15px;
    }
    .web-route-container .data-privacy,
    .web-route-container .data-privacy .contine-button {
        font-size: 19px;
    }
    .web-route-container .data-privacy .privacy-header span {
        font-size: 28px;
    }
    .web-route-container .data-privacy .privacy-header img {
        height: 50px;
    }
    .web-route-container .video-inlineMenu .button-toggle img {
        height: 35px;
        width: 35px;
    }
    .web-route-container .icon-hollow_arrow a.backBtn {
        width: 40px;
        height: 28px;
    }
    .web-route-container .SettingScreen a {
        font-size: 22px;
        margin: 10px 0;
    }
    

}

@media screen and (max-width: 1366px) {
    .web-route-container .jumbo-view .titleHolder p {
        font-size: 14px;
        padding: 0 7px;
    }
    .web-route-container .jumbo-view .swiper-container {
        min-height: 200px;
    }
    .web-route-container .jumbo-view h2 {
        width: 195px;
        transform: rotate(270deg) translate(-100px, -50px);
    }
    .web-route-container .jumbo-view .titleHolder {
        height: 55px;
    }
    .web-route-container .jumbo-view.portrait-view .titleHolder {
        height: 80px;
    }
    .web-route-container .video-inlineMenu .button-toggle img {
        height: 30px;
        width: 30px;
    }
    .web-route-container .data-privacy .switch-slider-wrapper {
        width: 78px;
    }
    .web-route-container .rePassword .reContent a {
        min-width: 150px;
        padding: 8px;
        font-size: 20px;
    }
    .web-route-container .video-title {
        min-width: 130px;
        max-width: 190px;
        padding: 6px 6px 10px;
    }
    .web-route-container .video-title p {
        font-size: 15px;
    }
}

@media screen and (max-width: 1280px) {
    .web-route-container #standard-grid.grid-view .ctaHolder,
    .web-route-container #standard-grid.grid-view h2 {
        font-size: 16px;
    }
    .web-route-container #standard-grid.grid-view .titleHolder p,
    .web-route-container #standard-grid.nothumbBox.grid-view .titleHolder p {
        font-size: 14px;
    }
    .web-route-container #standard-grid.grid-view .titleHolder {
        margin-top: 14px;
    }

    .web-route-container .detail-view-cls .swiper-container-vertical .detailContent .summaryHolder p:last-child {
        -webkit-line-clamp: 6;
    }
    .web-route-container .detail-view-cls > div > h2 {
        font-size: 26px;
    }
    .web-route-container .detail-view-cls .swiper-container-vertical .detailContent .summaryHolder h4,
    .web-route-container .detail-view-cls .swiper-container-vertical .detailContent .ctaHolder {
        font-size: 23px;
    }
    .web-route-container .detail-view-cls .swiper-container-vertical .detailContent .summaryHolder p {
        font-size: 20px;
    }
    .web-route-container .detail-view-cls .swiper-container-vertical .detailContent .saveIcon {
        bottom: 60px;
    }
    .web-route-container .data-privacy,
    .web-route-container .data-privacy .contine-button {
        font-size: 17px;
    }
    .web-route-container .data-privacy .privacy-header span {
        font-size: 26px;
    }
    .web-route-container .data-privacy .privacy-header img {
        height: 45px;
    }
    .web-route-container .video-inlineMenu .button-toggle img {
        height: 25px;
        width: 25px;
    }
    .web-route-container .user-access .logInScreen #account-form .btnForgot a {
        right: -206px;
    }
}

@media screen and (max-width: 1024px) {
    .video-grid .search-section .colorBg {
        background-color: #fff;
    }
    .video-grid .search-section .search-row {
        padding: 10px 4%;
        width: 100%;
        box-sizing: border-box;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .video-grid .search-section .search-row .titleHolder p {
        margin: 8px 0;
        font-size: 14px !important;
        color: #000;
    }
    .video-grid .search-section .search-row .titleHolder a {
        display: none;
    }
    .web-route-container .settings-logoHolder {
        width: 55%;
    }
    .web-route-container .settings-logoHolder img {
        max-height: 50px;
    }
    .web-route-container .SettingScreen {
        width: 45% !important;
        margin: 0 !important;
    }
    .web-route-container .SettingScreen ul{
        padding-left: 0px;
    }
    .web-route-container .SettingScreen a {
        width: 100%;
        box-sizing: border-box;
        font-size: 16px;
        margin: 5px 0;
    }
    .web-route-container .data-privacy,
    .web-route-container .data-privacy .contine-button {
        font-size: 16px;
    }
    .web-route-container .data-privacy .privacy-header span {
        font-size: 22px;
    }
    .web-route-container .data-privacy .privacy-header img {
        height: 40px;
    }
    .web-route-container .data-privacy .privacy-item p {
        padding: 0;
    }
    .web-feed-err h1 {
        padding: 0 10%;
        font-size: 16px;
    }
    .web-route-container .metering-counter {
        width: 140px;
        height: 35px;
        line-height: 25px;
        top: 15px;
        right: 15px;
    }
    .web-route-container .metering-counter span {
        font-size: 14px;
    }
    .web-route-container .user-access .logoHolder.smallImg img, 
    .web-route-container .subscribeScreen-wrapper .logoHolder.smallImg img {
        max-height: 50px;
    }
    .web-route-container .invalidContent h3 {
        font-size: 20px;
    }
    .web-route-container .subscribeScreen-wrapper .iapTitle,
    .web-route-container .rePassword .reContent p,
    .web-route-container .invalidContent p,
    .web-route-container .subscribeScreen p {
        font-size: 16px;
    }
    .web-route-container .user-access .logInScreen #account-form input,
    .web-route-container a.btn-dismiss,
    .web-route-container .subscribeScreen a.btn-trial {
        font-size: 14px;
    }
    .web-route-container .subscribeScreen .sub-unlock-note {
        font-size: 16px;
        margin-top: 0;
        padding: 0 3%;
    }
    .web-route-container .subscribeScreen-wrapper .disclaimer {
        padding: 5%;
        font-size: 9px;
    }
    .web-route-container .termsOfUse li a {
        font-size: 12px;
    }
    .web-route-container .rePassword .reContent a,
    .web-route-container .user-access .logInScreen #account-form span.btnBox button {
        font-size: 12px;
    }
    .web-route-container .user-access .logInScreen p {
        font-size: 16px;
        padding: 0 5%;
    }
    .web-route-container .user-access .logInScreen .btn-fbIcon,
    .web-route-container .user-access .logInScreen a.btn-email {
        font-size: 16px;
        padding: 10px;
    }
    .web-route-container .user-access .logInScreen .btn-fbIcon img {
        background-size: 20px 20px;
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
    .web-route-container .user-access .logInScreen a.btn-email .mail-icon {
        background-size: 25px 20px;
        height: 20px;
        width: 25px;
        margin-right: 10px;
    }
    .vjs-error .vjs-error-display .vjs-modal-dialog-content {
        font-size: 14px;
    }
    .web-route-container .confirm-modal button {
        font-size: 14px;
        padding: 8px;
        min-width: 120px;
    }
    .web-route-container .payment-page .pay-cnf-msg,
    .web-route-container .subscribeScreen .sub-item-details h4,
    .web-route-container .confirm-modal h3 {
        font-size: 20px;
    }
    .web-route-container .payment-page .pay-title,
    .web-route-container .payment-page .dur-price,
    .web-route-container .subscribeScreen .sub-item-details
    .web-route-container .confirm-modal p {
        font-size: 16px;
    }
    .web-route-container .payment-page {
        padding: 10px;
        box-sizing: border-box;
    }
    .web-route-container .payment-page .redirect-bck, 
    .web-route-container .payment-page .redirect-text {
        font-size: 14px;
    }
}
@media screen and (max-width: 680px) {
    .video-grid .search-section .search-row {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .video-grid .search-section .search-row .titleHolder p {
        font-size: 12px !important;
    }
    #web-nav-bar-mobile .web-search-box {
        width: 180px;
    }
    #web-nav-bar-mobile .missing-nav-logo {
        max-width: 270px;
        overflow: hidden;
    }
}
@media screen and (max-width: 420px) {
    .video-grid .search-section .search-row {
        grid-template-columns: 1fr 1fr;
    }
    .video-grid .search-section .search-row .titleHolder p {
        font-size: 11px !important;
    }
    #web-nav-bar-mobile .web-search-box {
        width: 150px;
    }
    .web-route-container .subscribeScreen a.btn-trial {
        min-width: calc(100% - 70px);
        max-width: calc(100% - 70px);
        font-size: 12px;
    }
    .web-route-container .user-access .logInScreen a.btn-email,
    .web-route-container .user-access .logInScreen .btn-fbIcon,
    .web-route-container .user-access .logInScreen #account-form input {
        max-width: calc(100% - 70px);
        min-width: calc(100% - 70px);
        margin-top: 10px;
    }
    .user-access .logInScreen #account-form .btnForgot {
        display: block;
    }
    .web-route-container .user-access .logInScreen #account-form span.btnBox {
        margin-top: 0;
    }
    .web-route-container .user-access .logInScreen #account-form span.btnBox button {
        min-width: 150px;
    }
    #web-nav-bar-mobile .missing-nav-logo {
        max-width: 120px;
        overflow: hidden;
    }
    .web-route-container .data-privacy,
    .web-route-container .data-privacy .contine-button {
        font-size: 14px;
    }
    /* .web-sponsor-mobile  .sponsorContent img {
        max-height: 80px;
    } */
}

@media screen and (max-width: 768px), (max-height: 768px) {
    .video-grid-content .swiper-slide {
        width: 160px;
    }
    .video-grid-content .ctaHolder,
    .video-grid-content h2 {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .video-grid-content .swiper-slide .titleHolder p {
        font-size: 14px;
    }
}
@media screen and (max-width: 480px), (max-height: 480px) {
    .video-grid-content .ctaHolder,
    .video-grid-content h2 {
        font-size: 16px;
    }
    .video-grid-content .swiper-slide .titleHolder p {
        font-size: 12px;
    }
}
@media screen and (max-width: 320px) {
    #web-nav-bar-mobile .missing-nav-logo {
        max-width: 85px;
        overflow: hidden;
    }
    #web-nav-bar-mobile .web-search-box {
        width: 110px;
    }
    #web-nav-bar-mobile .nav-logo {
        max-width: 85px;
    }
    #web-nav-bar-mobile {
        padding: 0 10px;
    }
}

@media (orientation: landscape) {
    .web-route-container-mobile .videoFull video {
        object-fit: contain;
        object-position: center;
    }
    .web-route-container-mobile .image-view .swiper-slide.image-full,
    .web-route-container-mobile .showGallery .swiper-slide {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .web-route-container-mobile .content-box .imageFull {
        height: calc(100% - 70px);
        width: 100%;
        margin-top: 70px;
        background-color: #000;
    }
    .web-route-container-mobile .content-box .imageFull img{
        object-fit: contain;
        object-position: center;
    }
    .poweredby-screen-mobile img {
        object-fit: contain;
    }
}
  
@media (orientation: portrait) {
    .web-route-container-mobile .videoFull video {
        object-fit: contain;
        object-position: center;
    }
    .web-route-container-mobile .with-modal .image-view {
        background-color: #000;
    }
    .web-route-container-mobile .image-view .swiper-slide.image-full,
    .web-route-container-mobile .showGallery .swiper-slide {
        background-size: contain !important;
        background-position: center center;
        background-repeat: no-repeat;
    }
    /* .web-sponsor.web-sponsor-mobile .imageBg img {
        object-fit: fill;
    } */
    .poweredby-screen-mobile img {
        object-fit: contain;
    }
    .web-route-container-mobile .content-box .imageFull {
        height: 100%;
        width: 100%;
        background-color: #000;
    }
    .web-route-container-mobile .content-box .imageFull img{
        object-fit: contain;
        object-position: center;
    }
}